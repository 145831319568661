<template>
    <div class="construction_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.eightMembersName" contText=""> </CourseTitle>
            <div class="content understand flex flex-wrap">
                <div class="understand_list " v-for="(item, index) in formData.constructionEightMembersVOS" :key="index">

                    <div class="top flex-center column" v-if="index == 0 || index == 1 || index == 2 || index == 3">
                        <div class="understand_list_text">{{ item.eightMembersIntroduce }}</div>
                        <div class="understand_list_name">{{ item.eightMembersName }}</div>
                        <img :src="item.eightMembersImgUrl" alt="">
                    </div>
                    <div class="bottom flex-center column" v-else>
                        <img :src="item.eightMembersImgUrl" alt="">
                        <div class="understand_list_name">{{ item.eightMembersName }}</div>
                        <div class="understand_list_text">{{ item.eightMembersIntroduce }}</div>

                    </div>
                </div>
                <div class="btn display pointer" @click="service">立即咨询 >></div>
            </div>
        </div>
        <div class="module2">
            <CoreCourses :coreCourseTitle="formData.coreCourseTitle"
                :registrationCoreCourse="formData.constructionCoreCourseVOS"
                :coreCourses="formData.constructionCoreCourseVOS[0]"></CoreCourses>
        </div>
        <div class="certificate_box">
            <CourseTitle :title="'证书样式'" contText=""> </CourseTitle>
            <div class="content display">
                <img class="certificate_img" :src="formData.certificateUrl" alt="">
            </div>
        </div>
        <div class="module3">
            <div class="content">
                <CourseTitle :title="formData.applicationGuideName" contText=""> </CourseTitle>
                <div class="signUp_content flex flex-wrap">
                    <div class="signUp_list pointer flex-center column"
                        v-for="(item, index) in formData.constructionApplicationGuideVOS" :key="index">
                        <img :src="item.applicationGuideImgUrl" alt="">
                        <div class="signUp_name">{{ item.applicationGuideName }}</div>
                        <div class="viewBtn display pointer" @click="service">查看详情</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module4">
            <div class="content">
                <CourseTitle :title="formData.applicationProcessName" :isWhite="1" contText=""> </CourseTitle>
                <ExaminationProcess :examinationProcess="formData.examinationProcess"></ExaminationProcess>
            </div>
        </div>
        <div class="module5">
            <div class="">
                <CourseTitle :title="formData.teachingTeamName" :contText="formData.teachingTeamIntroduce"> </CourseTitle>
                <Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
            </div>
        </div>
        <div class="module6">
            <LearningMaterials :type="2" :options="formData.constructionTeachingMaterialsVOS"
                :courseTitle="formData.teachingMaterialsName"></LearningMaterials>
        </div>
        <div class="module7">
            <div class="content">
                <CourseTitle :title="formData.preparationMaterialsName" contText=""> </CourseTitle>
                <CollectData :collectList="formData.constructionPreparationMaterialsVOS"></CollectData>
            </div>
        </div>
        <div class="module8">
            <ExaminationInformation :title="formData.applicationConsultationName"
                :contText="formData.applicationConsultationIntroduce"
                :province="formData.constructionApplicationConsultationProvinceVOS"
                :question="formData.constructionApplicationConsultationQuestionVOS"></ExaminationInformation>
        </div>
        <div class="module10">
            <SignUp :courseType="2" :courseId="this.formData.id"></SignUp>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import SignUp from '@/components/signUp'
import CoreCourses from '@/components/coreCourses'
import ExaminationProcess from '@/components/examinationProcess'
import Lecturer from '@/components/lecturer'
import LearningMaterials from '@/components/learningMaterials'
import CollectData from '@/components/collectData'
import ExaminationInformation from '@/components/examinationInformation'
export default {
    components: {
        BannerImg,
        CourseTitle,
        SignUp,
        CoreCourses,
        ExaminationProcess,
        Lecturer,
        LearningMaterials,
        CollectData,
        ExaminationInformation,
    },
    data() {
        return {
            value: '',
            classificationIndex: 0,
            understand: [],
            formData: {},
        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
        this.init()
    },
    methods: {
        init() {
            this.api.findConstructionManagerInfo().then(res => {
                if (res.code == 0) {
                    res.data.constructionTeachingMaterialsVOS.forEach(item => {
                        item.value = item.id
                        item.label = item.teachingMaterialsName
                    })
                    this.formData = res.data
                    let arr = []
                    let arr_s = []
                    arr = this.formData.constructionApplicationProcessVOS.slice(0, 4)
                    arr_s = this.formData.constructionApplicationProcessVOS.slice(4)
                    arr_s = arr_s.reverse()
                    this.formData.examinationProcess = [...arr, ...arr_s]
                }
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },


    }
}
</script>
<style lang="scss" scoped>
.construction_page {
    .module1 {
        height: 980px;
        background: url('../../assets/img/course/bg8.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 86px;

        .understand {
            width: 1200px;
            height: 580px;
            background: url('../../assets/img/course/bg8s.png') no-repeat;
            background-size: 100% 100%;
            margin-top: 49px;
            position: relative;

            .understand_list:nth-child(1) {
                position: absolute;
                top: -0px;
                left: 20px;
            }

            .understand_list:nth-child(2) {
                position: absolute;
                top: -0px;
                left: 292px;
            }

            .understand_list:nth-child(3) {
                position: absolute;
                top: -0px;
                right: 388px;
            }

            .understand_list:nth-child(4) {
                position: absolute;
                top: -0px;
                right: 92px;
            }

            .understand_list:nth-child(5) {
                position: absolute;
                top: 305px;
                left: 120px;
            }

            .understand_list:nth-child(6) {
                position: absolute;
                top: 305px;
                left: 405px;
            }

            .understand_list:nth-child(7) {
                position: absolute;
                top: 305px;
                right: 280px;
            }

            .understand_list:nth-child(8) {
                position: absolute;
                top: 305px;
                right: -20px;
            }

            .understand_list {
                width: 235px;

                img {
                    width: 114px;
                    height: 114px;
                }

                .understand_list_name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 30px;
                    margin-bottom: 22px;
                    text-align: center;
                }

                .understand_list_text {
                    width: 225px;
                    height: 68px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    text-align: center;
                }


            }
        }

        .btn {
            width: 430px;
            height: 48px;
            background: #FF881E;
            border-radius: 5px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            margin: 0 auto;
            margin-top: 620px;
        }
    }

    .module3 {
        .signUp_content {
            margin-top: 21px;
            margin-bottom: 30px;

            .signUp_list:nth-child(5n+1) {
                margin-left: 0px !important;
            }

            .signUp_list:hover {
                background: #fafafa;
                box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
            }

            .signUp_list {
                width: 218px;
                height: 247px;
                margin-left: 26px;
                margin-bottom: 30px;

                img {
                    width: 90px;
                    height: 90px;
                    margin-top: 30px;
                }

                .signUp_name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 19px;
                }

                .viewBtn {
                    width: 130px;
                    height: 38px;
                    border: 1px solid #00438C;
                    border-radius: 5px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #00438C;
                    margin-top: 27px;
                }
            }
        }
    }

    .module4 {
        background: url('../../assets/img/course/bg7.png') no-repeat;
        background-size: 100% 100%;
        min-height: 612px;
    }

    .module5 {
        padding-bottom: 120px;
    }

    .module7 {
        padding-bottom: 70px;
    }

    .certificate_box {
        height: 685px;
        background: url("../../assets/img/course/bg19.png") no-repeat;
        background-size: 100% 100%;

        .content {
            .certificate_img {
                width: 310px;
                height: 440px;
                margin-top: 21px;

            }
        }
    }
}</style>