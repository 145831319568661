import { render, staticRenderFns } from "./constructionSite.vue?vue&type=template&id=0b5c11b2&scoped=true&"
import script from "./constructionSite.vue?vue&type=script&lang=js&"
export * from "./constructionSite.vue?vue&type=script&lang=js&"
import style0 from "./constructionSite.vue?vue&type=style&index=0&id=0b5c11b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5c11b2",
  null
  
)

export default component.exports